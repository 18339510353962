import { gql } from '@apollo/client';

export const pageTitleActionsQuery = gql`
  query GetPageTitleActions($input: PageTitleActionsWidgetInput!) {
    analyticsPageTitleActions(input: $input) {
      isBranded
      isFeatured
      advertiserName
      classificationName
      displayCurrency
      isExpired
      isPrivateAdvertiser
      jobCreatedTimestampUTC
      jobExpiryTimestampUTC
      jobId
      jobLocationLabel
      jobSummary
      jobTitle
      keySellingPoints
      lastUpdatedTimestampUTC
      latestJobs {
        jobCreatedTimestampUTC
        jobId
        jobLocationLabel
        jobTitle
        userFullName
      }
      postTypeCategory
      salaryAdditionalText
      serpLogoUrl
      subClassificationName
    }
  }
`;
