import { Box } from 'braid-design-system';
import classNames from 'classnames';
import type * as React from 'react';

import * as styles from './circleBadge.css';

type CircleBadgeTone = 'critical' | 'positive' | 'info';
type CircleBadgeBackgroundTone =
  | 'criticalLight'
  | 'positiveLight'
  | 'infoLight';

const backgroundToneMap: Record<CircleBadgeTone, CircleBadgeBackgroundTone> = {
  critical: 'criticalLight',
  positive: 'positiveLight',
  info: 'infoLight',
};

export interface CircleBadgeProps {
  children: React.ReactNode;
  circleSize?: 'large' | 'standard' | 'small';
  tone?: CircleBadgeTone;
}

export const CircleBadge = ({
  children,
  circleSize = 'standard',
  tone = 'info',
}: CircleBadgeProps) => (
  <Box
    alignItems="center"
    background={backgroundToneMap[tone]}
    className={classNames({
      [styles.large]: circleSize === 'large',
      [styles.standard]: circleSize === 'standard',
      [styles.small]: circleSize === 'small',
    })}
    data={{ id: 'primitive-circle-badge' }}
    display="flex"
    justifyContent="center"
  >
    {children}
  </Box>
);
