import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from 'react';

export interface IIsFilterContext {
  isFilterAdPerformance: boolean;
  setIsFilterAdPerformance: Dispatch<SetStateAction<boolean>>;
  isFilterAdType: boolean;
  setIsFilterAdType: Dispatch<SetStateAction<boolean>>;
  isFilterAddOns: boolean;
  setIsFilterAddOns: Dispatch<SetStateAction<boolean>>;
  isFilterClassification: boolean;
  setIsFilterClassification: Dispatch<SetStateAction<boolean>>;
  isFilterJobTitle: boolean;
  setIsFilterJobTitle: Dispatch<SetStateAction<boolean>>;
  isFilterLocation: boolean;
  setIsFilterLocation: Dispatch<SetStateAction<boolean>>;
  isFilterAccountHierachy: boolean;
  setIsFilterAccountHierachy: Dispatch<SetStateAction<boolean>>;
  isFilterAdId: boolean;
  setIsFilterAdId: Dispatch<SetStateAction<boolean>>;
  isFilterAdStatus: boolean;
  setIsFilterAdStatus: Dispatch<SetStateAction<boolean>>;
  isFilterRepost: boolean;
  setIsFilterRepost: Dispatch<SetStateAction<boolean>>;
  isFilterTipsToImproveAds: boolean;
  setIsFilterTipsToImproveAds: Dispatch<SetStateAction<boolean>>;
  isFilterUser: boolean;
  setIsFilterUser: Dispatch<SetStateAction<boolean>>;
  isFilterBudget: boolean;
  setIsFilterBudget: Dispatch<SetStateAction<boolean>>;
}
export const IsFilterContext = createContext<IIsFilterContext>({
  isFilterAdPerformance: false,
  setIsFilterAdPerformance: () => {},
  isFilterAdType: false,
  setIsFilterAdType: () => {},
  isFilterAddOns: false,
  setIsFilterAddOns: () => {},
  isFilterClassification: false,
  setIsFilterClassification: () => {},
  isFilterJobTitle: false,
  setIsFilterJobTitle: () => {},
  isFilterLocation: false,
  setIsFilterLocation: () => {},
  isFilterAccountHierachy: false,
  setIsFilterAccountHierachy: () => {},
  isFilterAdId: false,
  setIsFilterAdId: () => {},
  isFilterAdStatus: false,
  setIsFilterAdStatus: () => {},
  isFilterRepost: false,
  setIsFilterRepost: () => {},
  isFilterTipsToImproveAds: false,
  setIsFilterTipsToImproveAds: () => {},
  isFilterUser: false,
  setIsFilterUser: () => {},
  isFilterBudget: false,
  setIsFilterBudget: () => {},
});

export const IsFilterContextProvider = ({
  children,
}: {
  children: ReactNodeNoStrings;
}) => {
  const [isFilterAdPerformance, setIsFilterAdPerformance] = useState(false);
  const [isFilterAdType, setIsFilterAdType] = useState(false);
  const [isFilterAddOns, setIsFilterAddOns] = useState(false);
  const [isFilterClassification, setIsFilterClassification] = useState(false);
  const [isFilterJobTitle, setIsFilterJobTitle] = useState(false);
  const [isFilterLocation, setIsFilterLocation] = useState(false);
  const [isFilterAccountHierachy, setIsFilterAccountHierachy] = useState(false);
  const [isFilterAdId, setIsFilterAdId] = useState(false);
  const [isFilterAdStatus, setIsFilterAdStatus] = useState(false);
  const [isFilterRepost, setIsFilterRepost] = useState(false);
  const [isFilterTipsToImproveAds, setIsFilterTipsToImproveAds] =
    useState(false);
  const [isFilterUser, setIsFilterUser] = useState(false);
  const [isFilterBudget, setIsFilterBudget] = useState(false);

  return (
    <IsFilterContext.Provider
      value={{
        isFilterAdPerformance,
        setIsFilterAdPerformance,
        isFilterAdType,
        setIsFilterAdType,
        isFilterAddOns,
        setIsFilterAddOns,
        isFilterClassification,
        setIsFilterClassification,
        isFilterJobTitle,
        setIsFilterJobTitle,
        isFilterLocation,
        setIsFilterLocation,
        isFilterAccountHierachy,
        setIsFilterAccountHierachy,
        isFilterAdId,
        setIsFilterAdId,
        isFilterAdStatus,
        setIsFilterAdStatus,
        isFilterRepost,
        setIsFilterRepost,
        isFilterTipsToImproveAds,
        setIsFilterTipsToImproveAds,
        isFilterUser,
        setIsFilterUser,
        isFilterBudget,
        setIsFilterBudget,
      }}
    >
      {children}
    </IsFilterContext.Provider>
  );
};
