import { useTranslations } from '@vocab/react';
import {
  Box,
  Column,
  Columns,
  IconClear,
  IconSearch,
  IconTick,
  List,
  Stack,
  Strong,
  Text,
} from 'braid-design-system';
import { useState, type ReactNode } from 'react';

import type { PostTypeCategory } from 'src/types/AdPerformanceTypes';
import { formatPercentage } from 'src/utils/percentage/percentageFormatter';
import { trackEvent } from 'src/utils/tealiumEventTracker';

import { CircleBadge } from '../CircleBadge/CircleBadge';
import { JobSkeleton } from '../JobSkeleton/JobSkeleton';
import { RecommendationSkeleton } from '../RecommendationSkeleton/RecommendationSkeleton';
import { StandardBox } from '../StandardBox/StandardBox';
import { VerticalTabBar } from '../VerticalTabBar/VerticalTabBar';

import localTranslations from './.vocab';

export type SearchRecommendationTabId =
  | 'job-title'
  | 'key-selling-points'
  | 'job-summary';

export interface SearchRecommendationProps {
  comparisonConversionRate: number;
  conversionRate: number;
  isExpired?: boolean;
  postTypeCategory?: PostTypeCategory;
  editAdUrl: string;
  direction?: 'column' | 'row';
  coverTitle?: string;
  coverSubTitle?: ReactNodeNoStrings;
  showSubTitle?: boolean;
  footerNotice?: ReactNodeNoStrings;
  trackInteractionObject?: string;
  onEditAdButtonClicked?: () => void;
  onEditAdIndirectButtonClicked?: () => void;
  onTipClicked?: (props: {
    eventName: string;
    tabName: string;
    tipName: string;
    objectInteraction: string;
    tab?: string;
    expandedSection?: string;
  }) => void;
}

export const SearchRecommendation = ({
  comparisonConversionRate,
  conversionRate,
  isExpired,
  postTypeCategory,
  editAdUrl,
  coverTitle,
  coverSubTitle,
  direction = 'row',
  showSubTitle = true,
  footerNotice = null,
  trackInteractionObject,
  onEditAdButtonClicked,
  onEditAdIndirectButtonClicked,
  onTipClicked,
}: SearchRecommendationProps) => {
  const { t } = useTranslations(localTranslations);

  const initialActiveTab = 'job-title';
  const [activeTab, setActiveTab] =
    useState<SearchRecommendationTabId>(initialActiveTab);

  const toggleModalTrackEvent = (isOpen: boolean) => {
    if (isOpen) {
      if (onTipClicked) {
        onTipClicked({
          eventName: 'view_tip_pressed',
          tabName: 'recommendation',
          tipName: 'review ads summary',
          objectInteraction: 'view-ads-summary-tip-interacted',
        });
      } else {
        trackEvent('view_tip_pressed', {
          tipName: 'search',
          objectInteraction:
            trackInteractionObject || 'apr-recommendations-search-section',
        });
      }
    }
  };

  const onTabButtonChanged = (tab: SearchRecommendationTabId) => {
    setActiveTab(tab);
  };

  const onTabButtonClickedEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
    const currentTarget = e.currentTarget as HTMLButtonElement;
    const tab = currentTarget.dataset.tab;
    if (onTipClicked) {
      onTipClicked({
        eventName: 'tip_tab_pressed',
        tabName: 'job title',
        tipName: 'review ads summary',
        objectInteraction: `view-ads-summary-tip-${tab}-interacted`,
        tab,
      });
    } else {
      trackEvent('tip_tab_pressed', {
        tipName: 'search',
        tabName: tab?.split('-').join(' '),
        objectInteraction: `apr-recommendations-search-${tab}`,
      });
    }
  };

  const title = t('Review what candidates see in the search');
  const subTitle = t(
    '{conversionPercentage} of people who see your ad in the search are clicking it (similar ads average {comparisonConversionPercentage}).',
    {
      conversionPercentage: formatPercentage(conversionRate, 1),
      comparisonConversionPercentage: formatPercentage(
        comparisonConversionRate,
        1,
      ),
      Strong: (children: ReactNode) => <Strong>{children}</Strong>,
    },
  );

  return (
    <RecommendationSkeleton
      coverTitle={coverTitle || title}
      coverSubTitle={coverSubTitle || subTitle}
      titleContent={title}
      subtitleContent={subTitle}
      isExpired={isExpired}
      postTypeCategory={postTypeCategory}
      editAdUrl={editAdUrl}
      onEditAdButtonClicked={onEditAdButtonClicked}
      onEditAdIndirectButtonClicked={onEditAdIndirectButtonClicked}
      coverIcon={<IconSearch tone="info" />}
      toggleModalTrackEvent={toggleModalTrackEvent}
      dataId="ui-search-recommendation"
      direction={direction}
      showSubTitle={showSubTitle}
    >
      <Stack space="large">
        <Columns space="large" collapseBelow="tablet">
          <Column width="content">
            <VerticalTabBar<SearchRecommendationTabId>
              activeTab={initialActiveTab}
              tabs={[
                {
                  id: 'job-title',
                  label: t('Job title'),
                },
                {
                  id: 'key-selling-points',
                  label: t('Key selling points'),
                },
                {
                  id: 'job-summary',
                  label: t('Job summary'),
                },
              ]}
              onTabButtonClicked={onTabButtonClickedEvent}
              onTabButtonChanged={onTabButtonChanged}
            />
          </Column>
          <Column>
            <Stack space="medium">
              <Columns space="xlarge" collapseBelow="tablet">
                <Column>
                  {activeTab === 'job-title' &&
                    t('Job title tip', {
                      Stack: (children: ReactNodeNoStrings) => (
                        <Stack space="medium">{children}</Stack>
                      ),
                      Strong: (children) => (
                        <Text weight="strong">{children}</Text>
                      ),
                      Text: (children) => <Text>{children}</Text>,
                    })}
                  {activeTab === 'key-selling-points' &&
                    t('Key selling points tip', {
                      Stack: (children: ReactNodeNoStrings) => (
                        <Stack space="medium">{children}</Stack>
                      ),
                      List: (children: ReactNodeNoStrings) => (
                        <List>{children}</List>
                      ),
                      Text: (children) => <Text>{children}</Text>,
                    })}
                  {activeTab === 'job-summary' &&
                    t('Job summary tip', {
                      Stack: (children: ReactNodeNoStrings) => (
                        <Stack space="medium">{children}</Stack>
                      ),
                      List: (children: ReactNodeNoStrings) => (
                        <List>{children}</List>
                      ),
                      Strong: (children) => (
                        <Text weight="strong">{children}</Text>
                      ),
                      Text: (children) => <Text>{children}</Text>,
                    })}
                </Column>
                <Column width="content">
                  <JobSkeleton activeSection={activeTab} />
                </Column>
              </Columns>
              {activeTab === 'job-title' && (
                <Stack space="medium">
                  <Text weight="strong">{t('Examples of job title')}</Text>
                  <Stack space="small">
                    <StandardBox
                      accessory={
                        <CircleBadge tone="positive">
                          <IconTick size="small" tone="positive" />
                        </CircleBadge>
                      }
                      borderTone="positive"
                      height="full"
                      subTitleComponent={t('Good job title examples', {
                        List: (children: ReactNodeNoStrings) => (
                          <List>{children}</List>
                        ),
                        Text: (children) => <Text>{children}</Text>,
                      })}
                    />
                    <StandardBox
                      accessory={
                        <CircleBadge tone="critical">
                          <IconClear size="small" tone="critical" />
                        </CircleBadge>
                      }
                      borderTone="negative"
                      height="full"
                      subTitleComponent={t('Bad job title examples', {
                        List: (children: ReactNodeNoStrings) => (
                          <List>{children}</List>
                        ),
                        Text: (children) => <Text>{children}</Text>,
                      })}
                    />
                  </Stack>
                </Stack>
              )}
              {activeTab === 'job-summary' && (
                <Box marginTop="medium">
                  <Stack space="medium">
                    <Text weight="strong">{t('Examples of job summary')}</Text>
                    <Stack space="small">
                      <StandardBox
                        accessory={
                          <CircleBadge tone="positive">
                            <IconTick size="small" tone="positive" />
                          </CircleBadge>
                        }
                        borderTone="positive"
                        height="full"
                        subTitleText={t('Good job summary example')}
                      />
                      <StandardBox
                        accessory={
                          <CircleBadge tone="critical">
                            <IconClear size="small" tone="critical" />
                          </CircleBadge>
                        }
                        borderTone="negative"
                        height="full"
                        subTitleText={t('Bad job summary example')}
                      />
                    </Stack>
                  </Stack>
                </Box>
              )}
            </Stack>
          </Column>
        </Columns>
        {footerNotice}
      </Stack>
    </RecommendationSkeleton>
  );
};
