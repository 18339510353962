import classNames from 'classnames';
import { select, type Selection } from 'd3-selection';
import { line } from 'd3-shape';

import type { AdRatingScore } from 'src/types/AdPerformanceTypes';
import { formatNumber } from 'src/utils/number';

import type {
  AdRatingSegment,
  AdRatingChartStyles,
  AdRatingChartData,
  AdRatingChartRenderer,
  GaugeLabels,
  GaugeTooltipsData,
} from './AdRatingChart.types';

const gaugeScale = 1.1838235294;
const needleTopOffset = 20 * gaugeScale;
const needleLeftOffset = 20 * gaugeScale;
const naturalGaugeHeight = 136;
const naturalGaugeWidth = 272;
const labelWidth = 141;

const ratingPathMap: Record<AdRatingScore, AdRatingSegment> = {
  LOW: {
    backgroundPath:
      'M0,135.922063 L29.9277937,135.922063 C29.9277937,97.1948745 50.7014235,63.3234705 81.7097352,44.829341 L66.7439679,18.9081307 C26.7922338,42.5785215 0,86.1178499 0,135.922063',
    backgroundTone: 500,
    labelPathId: 'LOW-text-path-def',
    labelPath:
      'M16.9278 129.014C16.9278 90.2867 37.7014 56.4153 68.7097 37.9212',
    shadowPath:
      'M0,135.922063 L29.9277937,135.922063 C29.9277937,97.1948745 50.7014235,63.3234705 81.7097352,44.829341 L66.7439679,18.9081307 C26.7922338,42.5785215 0,86.1178499 0,135.922063',
  },
  NORMAL: {
    backgroundPath:
      'M135.922063,29.9277937 C154.842664,29.9277937 172.601068,34.8908195 187.977096,43.5773616 L202.94411,17.6536573 C183.164956,6.42075874 160.29451,0 135.922063,0 C111.549616,0 88.6791702,6.42075874 68.900016,17.6536573 L83.8670304,43.5773616 C99.2430579,34.8908195 117.001462,29.9277937 135.922063,29.9277937',
    backgroundTone: 700,
    labelPathId: 'NORMAL-text-path-def',
    labelPath:
      'M83.967 30.5774 C99.343 21.8908 117.101 16.9278 136.022 16.927 M136.022 16.9278 C154.943 16.9278 172.701 21.8908 188.077 30.5774',
    shadowPath:
      'M135.922063,29.9277937 C154.842664,29.9277937 172.601068,34.8908195 187.977096,43.5773616 L202.94411,17.6536573 C183.164956,6.42075874 160.29451,0 135.922063,0 C111.549616,0 88.6791702,6.42075874 68.900016,17.6536573 L83.8670304,43.5773616 C99.2430579,34.8908195 117.001462,29.9277937 135.922063,29.9277937',
  },
  HIGH: {
    backgroundPath:
      'M205.100221,18.9082554 L190.134453,44.8294657 C221.142141,63.3235952 241.916395,97.1949992 241.916395,135.922188 L271.844188,135.922188 C271.844188,86.1179746 245.051955,42.5786462 205.100221,18.9082554',
    backgroundTone: 900,
    labelPathId: 'HIGH-text-path-def',
    labelPath: 'M203 37.9212C234.008 56.4153 254.782 90.2868 254.782 129.014',
    shadowPath:
      'M205.100221,18.9082554 L190.134453,44.8294657 C221.142141,63.3235952 241.916395,97.1949992 241.916395,135.922188 L271.844188,135.922188 C271.844188,86.1179746 245.051955,42.5786462 205.100221,18.9082554',
  },
};

function constrainedNeedleAngleFromPercent(percent: number): number {
  const minAngle = -90;
  const maxAngle = 90;
  const angle = -90 + 180 * percent;
  return Math.max(minAngle, Math.min(maxAngle, angle));
}

function needleAngleFromRating(rating: AdRatingScore): number {
  if (rating === 'HIGH') {
    return constrainedNeedleAngleFromPercent(0.825);
  }
  if (rating === 'LOW') {
    return constrainedNeedleAngleFromPercent(0.165);
  }
  return constrainedNeedleAngleFromPercent(0.5);
}

function createLayer({
  s,
}: {
  s: Selection<SVGGElement, unknown, null, undefined>;
}) {
  return s.append('g');
}

function createFilters({
  s,
}: {
  s: Selection<SVGSVGElement, unknown, null, undefined>;
}) {
  const defs = s.append('defs');

  const boxShadowFilter = defs
    .append('filter')
    .attr('x', '-87.3%')
    .attr('y', '-37.6%')
    .attr('width', '232.1%')
    .attr('height', '209.0%')
    .attr('filter-units', 'objectBoundingBox')
    .attr('id', 'boxShadowFilter');
  boxShadowFilter
    .append('feMorphology')
    .attr('radius', 3)
    .attr('operator', 'dilate')
    .attr('in', 'SourceAlpha')
    .attr('result', 'shadowSpreadOuter1');
  boxShadowFilter
    .append('feOffset')
    .attr('dx', '-4')
    .attr('dy', '4')
    .attr('in', 'shadowSpreadOuter1')
    .attr('result', 'shadowOffsetOuter1');
  boxShadowFilter
    .append('feGaussianBlur')
    .attr('stdDeviation', '4.5')
    .attr('in', 'shadowOffsetOuter1')
    .attr('result', 'shadowBlurOuter1');
  boxShadowFilter
    .append('feColorMatrix')
    .attr(
      'values',
      '0 0 0 0 0.694846002   0 0 0 0 0.726241053   0 0 0 0 0.780061141  0 0 0 0.606452142 0',
    )
    .attr('type', 'matrix')
    .attr('in', 'shadowBlurOuter1');
}

function createGaugeRatingTextPaths({
  s,
}: {
  s: Selection<SVGSVGElement, unknown, null, undefined>;
}) {
  const defs = s.append('defs');

  defs
    .append('path')
    .attr('id', ratingPathMap.LOW.labelPathId)
    .attr('d', ratingPathMap.LOW.labelPath);
  defs
    .append('path')
    .attr('id', ratingPathMap.NORMAL.labelPathId)
    .attr('d', ratingPathMap.NORMAL.labelPath);
  defs
    .append('path')
    .attr('id', ratingPathMap.HIGH.labelPathId)
    .attr('d', ratingPathMap.HIGH.labelPath);
}

function updateNeedleAnimator({
  adRatingChartStyles,
  gaugeHeight,
  parent,
  theme,
}: {
  adRatingChartStyles: AdRatingChartStyles;
  gaugeHeight: number;
  parent: Selection<
    SVGGElement,
    {
      adRating: AdRatingScore;
      needleAngle: number;
    },
    SVGGElement,
    unknown
  >;
  theme: ChartTheme;
}) {
  const needleAnimator = parent
    .append('g')
    .attr('class', (d) =>
      classNames('needleAnimator', adRatingChartStyles.needleAnimator, {
        [adRatingChartStyles.lowRatingNeedleAnimator]: d.adRating === 'LOW',
        [adRatingChartStyles.normalRatingNeedleAnimator]:
          d.adRating === 'NORMAL',
        [adRatingChartStyles.highRatingNeedleAnimator]: d.adRating === 'HIGH',
      }),
    )
    .attr('style', (d) => `transform: rotate(${d.needleAngle}deg);`)
    .attr(
      'transform-origin',
      `${needleLeftOffset}px ${gaugeHeight * gaugeScale - needleTopOffset}px`,
    );
  const needlePointer = needleAnimator
    .append('g')
    .attr('class', 'needlePointer');

  needlePointer
    .append('path')
    .attr(
      'd',
      'M23.2043591,8.34823725 L33.8425897,34.1248432 C34.2639783,35.1458747 33.7778717,36.3151873 32.7568402,36.736576 C32.5156177,36.8361306 32.2572438,36.8875154 31.9962854,36.8878333 L10.0627862,36.9145572 C8.95821752,36.9159031 8.06169669,36.0215642 8.06035088,34.9169955 C8.06001353,34.6401177 8.11716934,34.3661859 8.22819933,34.1125449 L19.5234679,8.30921507 C19.9664079,7.29734653 21.1457632,6.83613882 22.1576318,7.27907882 C22.6317303,7.48661287 23.0069227,7.86984556 23.2043591,8.34823725 Z',
    )
    .attr('class', 'needlePointerShadow')
    .attr('filter', 'url(#boxShadowFilter)');
  needlePointer
    .append('path')
    .attr('class', 'needlePointerFill')
    .attr(
      'd',
      'M22.7591421,5.90496627 C21.8737572,5.51739378 20.9150967,5.5253859 20.0808894,5.85161739 C19.2466821,6.17784888 18.5369279,6.82231973 18.1493554,7.7077047 L6.85408679,33.5110346 C6.6597843,33.9549063 6.55976163,34.434287 6.56035199,34.9188231 C6.56152958,35.8853207 6.95434623,36.7598428 7.58849289,37.392446 C8.22263955,38.0250493 9.09811621,38.4157337 10.0646138,38.4145561 L31.998113,38.3878322 C32.4547902,38.3872758 32.9069446,38.2973524 33.3290839,38.1231318 C34.2224865,37.7544168 34.8818594,37.058485 35.225703,36.2313813 C35.5695465,35.4042776 35.5978606,34.4460021 35.2291455,33.5525995 L24.5909149,7.77599353 C24.2454012,6.93880808 23.5888145,6.26815087 22.7591421,5.90496627 Z',
    )
    .attr('fill', theme.tokens.colour.grey[700])
    .attr('fill-rule', 'evenodd')
    .attr('stroke', theme.tokens.systemColour.white)
    .attr('stroke-width', 3);
  return parent;
}

function updateGauge({
  gauge,
  gaugeLabels,
  theme,
  onSegmentEnter,
  onSegmentExit,
}: {
  adRating: AdRatingScore;
  gauge: Selection<SVGGElement, unknown, null, undefined>;
  gaugeLabels: GaugeLabels;
  theme: ChartTheme;
  onSegmentEnter: (segment: AdRatingScore) => void;
  onSegmentExit: (segment: AdRatingScore) => void;
}) {
  gauge
    .selectAll('.segment')
    .data<AdRatingScore>(['HIGH', 'NORMAL', 'LOW'])
    .join(
      (enter) => {
        const segment = enter.append('g').attr('class', 'segment');
        segment
          .append('path')
          .attr('class', 'shadow')
          .attr('d', (d) => ratingPathMap[d].shadowPath)
          .attr('filter', 'url(#boxShadowFilter)')
          .attr('opacity', () => 0); // hide gauge shadow until we need it again
        segment
          .append('path')
          .attr('class', 'background')
          .attr('d', (d) => ratingPathMap[d].backgroundPath)
          .attr(
            'fill',
            (d) => theme.tokens.colour.blue[ratingPathMap[d].backgroundTone],
          )
          .on('mouseover', (_, d) => {
            // @ts-ignore need to fix d3-selection types
            onSegmentEnter(d);
          })
          .on('mouseout', (_, d) => {
            // @ts-ignore need to fix d3-selection types
            onSegmentExit(d);
          });
        segment
          .append('text')
          .attr('font-size', '12px')
          .attr(
            'font-family',
            'SeekSans, "SeekSans Fallback", Arial, Tahoma, sans-serif',
          )
          .attr('font-weight', '500')
          .attr('letter-spacing', '0.4px')
          .attr('pointer-events', 'none')
          .append('textPath')
          .attr('class', 'label')
          .attr('href', (d) => `#${ratingPathMap[d].labelPathId}`)
          .attr('fill', theme.tokens.systemColour.white)
          .attr('startOffset', '50%')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'central')
          .text((d) => {
            if (d === 'HIGH') {
              return gaugeLabels.ratingHigh;
            } else if (d === 'LOW') {
              return gaugeLabels.ratingLow;
            }
            return gaugeLabels.ratingNormal;
          });
        return segment;
      },
      (update) =>
        update
          .select('.shadow')
          .attr('d', (d) => ratingPathMap[d].shadowPath)
          .select('.background')
          .attr('d', (d) => ratingPathMap[d].backgroundPath)
          .attr(
            'fill',
            (d) => theme.tokens.colour.blue[ratingPathMap[d].backgroundTone],
          )
          .on('mouseover', null)
          .on('mouseout', null)
          .on('mouseover', onSegmentEnter)
          .on('mouseout', onSegmentExit),
      (exit) => exit.remove(),
    );
}

function updateNeedle({
  adRating,
  adRatingChartStyles,
  gaugeHeight,
  gaugeWidth,
  needleAngle,
  parent,
  theme,
}: {
  adRating: AdRatingScore;
  adRatingChartStyles: AdRatingChartStyles;
  gaugeHeight: number;
  gaugeWidth: number;
  needleAngle: number;
  parent: Selection<SVGGElement, unknown, null, undefined>;
  theme: ChartTheme;
}) {
  parent
    .selectAll<
      SVGGElement,
      {
        adRating: AdRatingScore;
        needleAngle: number;
      }
    >('.needle')
    .data([{ adRating, needleAngle }])
    .join(
      (enter) => {
        const needle = enter
          .append('g')
          .attr('class', 'needle')
          .attr(
            'transform',
            `translate(${
              gaugeWidth / 2 - needleLeftOffset
            }, ${needleTopOffset})`,
          )
          .attr('pointer-events', 'none');

        updateNeedleAnimator({
          adRatingChartStyles,
          gaugeHeight,
          parent: needle,
          theme,
        });
        return needle;
      },
      (update) => {
        update.select('.needleAnimator').remove();
        updateNeedleAnimator({
          adRatingChartStyles,
          gaugeHeight,
          parent: update,
          theme,
        });
        return update;
      },
      (exit) => exit.remove(),
    );
}

function updateLabels({
  gaugeWidth,
  labelVisibility,
  normalMax,
  normalMin,
  parent,
  theme,
  gaugeLabels,
}: {
  gaugeWidth: number;
  labelVisibility: Record<AdRatingScore, boolean>;
  normalMax: number;
  normalMin: number;
  parent: Selection<SVGGElement, unknown, null, undefined>;
  theme: ChartTheme;
  gaugeLabels: GaugeLabels;
}) {
  const centerOffset = gaugeWidth / 2;
  const adRatings: AdRatingScore[] = ['HIGH', 'NORMAL', 'LOW'];
  const data = adRatings.map((k) => ({
    label: k,
    normalMax,
    normalMin,
    show: labelVisibility[k],
  }));

  parent
    .selectAll('.label')
    .data<GaugeTooltipsData>(data)
    .join(
      (enter) => {
        const label = enter
          .append('g')
          .attr('class', 'label')
          .attr('opacity', (d) => (d.show ? 1 : 0))
          .attr('transform', (d) => {
            const angle = needleAngleFromRating(d.label);
            const x =
              centerOffset +
              (gaugeWidth / 2) * Math.sin(angle * (Math.PI / 180));
            const y =
              centerOffset -
              (gaugeWidth / 2) * Math.cos(angle * (Math.PI / 180));
            return `translate(${x},${y})`;
          })
          .attr('style', `transition: opacity ${theme.tokens.motion.normal};`);
        label
          .append('circle')
          .attr('fill', theme.tokens.colour.grey[500])
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', 2);

        label
          .append('path')
          .attr('d', (d) =>
            line()([
              [0, 0],
              [14 * (d.label === 'LOW' ? -1 : 1), -12],
              [24 * (d.label === 'LOW' ? -1 : 1), -12],
            ]),
          )
          .attr('fill', 'none')
          .attr('stroke', theme.tokens.colour.grey[500])
          .attr('stroke-width', 0.6)
          .attr('stroke-linecap', 'square');
        const box = label.append('g').attr('class', 'box');

        box
          .append('rect')
          .attr('stroke', theme.tokens.colour.grey[500])
          .attr('stroke-width', 0.6)
          .attr('fill', theme.tokens.systemColour.white)
          .attr('rx', 3)
          .attr('width', 155)
          .attr('height', 35);

        const text = box.append('text');
        const tooltipText = text.append('tspan');

        tooltipText
          .attr('class', 'tooltip')
          .attr('x', 10.5)
          .attr('y', 23)
          .text((d) => {
            const formattedNormalMax = formatNumber(d.normalMax);
            const formattedNormalMin = formatNumber(d.normalMin);

            if (d.label === 'HIGH') {
              return gaugeLabels.contentHigh(formattedNormalMax);
            } else if (d.label === 'LOW') {
              return gaugeLabels.contentLow(formattedNormalMin);
            }
            return gaugeLabels.contentNormal(
              formattedNormalMin,
              formattedNormalMax,
            );
          });

        return label;
      },
      (update) =>
        update
          .attr('opacity', (d) => (d.show ? 1 : 0))
          .select('.rangeText')
          .text((d) => {
            const formattedNormalMax = formatNumber(d.normalMax);
            const formattedNormalMin = formatNumber(d.normalMin);

            if (d.label === 'HIGH') {
              return gaugeLabels.contentHigh(formattedNormalMax);
            } else if (d.label === 'LOW') {
              return gaugeLabels.contentLow(formattedNormalMin);
            }
            if (d.normalMin === 1 && d.normalMax === 1) {
              return ` : ${formatNumber(1)}`;
            }
            return gaugeLabels.contentNormal(
              formattedNormalMin,
              formattedNormalMax,
            );
          }),
      (exit) => exit.remove(),
    );

  parent
    .selectAll<SVGRectElement, unknown>('rect')
    .data<GaugeTooltipsData>(data)
    .attr('width', function () {
      const { width: textWidth, x: textLeft } = (
        this.nextSibling as SVGTextElement
      ).getBBox();
      return textLeft + textWidth + textLeft;
    });

  parent
    .selectAll<SVGGElement, unknown>('.box')
    .data<GaugeTooltipsData>(data)
    .attr('transform', function (d) {
      const { width: boxWidth } = (this.firstChild as SVGRectElement).getBBox();
      return `translate(${d.label === 'LOW' ? -1 * (boxWidth + 24) : 24}, -29)`;
    });
}

export function createAdRatingChartRenderer({
  adRatingChartStyles,
  container,
  containerHeight,
  containerWidth,
  textStyles,
}: {
  adRatingChartStyles: AdRatingChartStyles;
  container: HTMLDivElement | null;
  containerHeight: number;
  containerWidth: number;
  textStyles: TypographyStyles;
}): AdRatingChartRenderer | null {
  if (!container) {
    return null;
  }

  let horizontalMargin = (containerWidth - naturalGaugeWidth * gaugeScale) / 2;
  const gaugeMargin = {
    top: 35,
    right: horizontalMargin,
    bottom: 0,
    left: horizontalMargin,
  };
  const gaugeHeight = naturalGaugeHeight;
  const gaugeWidth = naturalGaugeWidth * gaugeScale;

  const rootSelection = select(container);
  const svgSelection = rootSelection
    .append('svg')
    .attr('width', containerWidth)
    .attr('height', containerHeight);
  createFilters({ s: svgSelection });
  createGaugeRatingTextPaths({ s: svgSelection });
  const rootNode = svgSelection
    .append('g')
    .attr('transform', `translate(${gaugeMargin.left}, ${gaugeMargin.top})`);

  const gauge = createLayer({ s: rootNode })
    .attr('class', 'gauge')
    .attr('transform', `scale(${gaugeScale})`);

  const labelLayer = createLayer({ s: rootNode }).attr(
    'class',
    classNames('label', textStyles.caption1),
  );

  let data: AdRatingChartData | undefined;
  const update = ({
    data: newData,
    theme,
    width,
    gaugeLabels,
  }: {
    data: AdRatingChartData;
    theme: ChartTheme;
    width: number;
    gaugeLabels: GaugeLabels;
  }) => {
    data = newData;
    if (data === undefined) {
      return;
    }
    const needleAngle = needleAngleFromRating(data.adRating);
    svgSelection.attr('width', width);
    horizontalMargin = (width - naturalGaugeWidth * gaugeScale) / 2;
    gaugeMargin.right = horizontalMargin;
    gaugeMargin.left = horizontalMargin;

    rootNode.attr(
      'transform',
      `translate(${gaugeMargin.left}, ${gaugeMargin.top})`,
    );

    const labelVisibility = {
      LOW: false,
      NORMAL: false,
      HIGH: false,
    };

    function onSegmentEnter(segment: AdRatingScore) {
      if (data === undefined) {
        return;
      }
      labelVisibility[segment] = horizontalMargin > labelWidth;
      updateLabels({
        gaugeWidth,
        labelVisibility,
        normalMax: data.normalMax,
        normalMin: data.normalMin,
        parent: labelLayer,
        theme,
        gaugeLabels,
      });
    }
    function onSegmentExit(segment: AdRatingScore) {
      if (data === undefined) {
        return;
      }
      labelVisibility[segment] = false;
      updateLabels({
        gaugeWidth,
        labelVisibility,
        normalMax: data.normalMax,
        normalMin: data.normalMin,
        parent: labelLayer,
        theme,
        gaugeLabels,
      });
    }

    updateGauge({
      adRating: data.adRating,
      gauge,
      gaugeLabels,
      theme,
      onSegmentEnter,
      onSegmentExit,
    });
    updateNeedle({
      adRating: data.adRating,
      adRatingChartStyles,
      gaugeHeight,
      gaugeWidth,
      needleAngle,
      parent: rootNode,
      theme,
    });
    updateLabels({
      gaugeWidth,
      labelVisibility,
      normalMax: data.normalMax,
      normalMin: data.normalMin,
      parent: labelLayer,
      theme,
      gaugeLabels,
    });
  };

  return {
    update,
  };
}
