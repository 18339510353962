import { graphql } from 'src/gql';

export const similarAdsQuery = graphql(`
  query GetSimilarData($input: SimilarAdsWidgetInput!) {
    analyticsSimilarAdsDetails(input: $input) {
      ads {
        advertiserName
        hasKeySellingPoints
        isPrivateAdvertiser
        isSalaryHidden
        jobCreatedTimestampUTC
        jobId
        jobLocationLabel
        jobTitle
        analyticsProductDisplayName
        serpLogoUrl
        showProductBadge
      }
      benchmarkLocationLabel
      benchmarkScenario
      classificationName
      normalisedRoleTitle
      subClassificationName
      benchmarkLocationCountry
      lastUpdatedTimestampUTC
    }
  }
`);
