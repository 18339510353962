import { formatMinorUnits } from '@seek/currency';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Disclosure,
  Loader,
  Notice,
  Stack,
  Text,
} from 'braid-design-system';

import { useConfig } from 'src/App/ConfigContext';
import { SpendCard } from 'src/components/SpendCard/SpendCard';
import { WidgetError } from 'src/components/WidgetError/WidgetError';
import { getLocale } from 'src/config';
import { useSpendData } from 'src/hooks/useSpendData';
import type { AdTypeAverageCost } from 'src/types/AdUsageSpendResponse';
import { getSiteCurrency } from 'src/utils/currency';
import { percentFromNumbersWithoutDecimal } from 'src/utils/formatters/percentFormatter';

import translations from './.vocab';

import * as styles from './Spend.css';

export const Spend = () => {
  const { t } = useTranslations(translations);
  const { data: spendTabData, isLoading, error } = useSpendData();
  const { site } = useConfig();

  if (isLoading && !spendTabData) {
    return (
      <Box display="flex" justifyContent="center">
        <Loader size="small" />
      </Box>
    );
  }

  if (error) {
    return <WidgetError />;
  }

  if (!spendTabData) return null;

  const {
    averageCostPerApplication,
    averageApplications,
    averageCostPerAd,
    hidePrice,
    repostedAdsCount,
    repostedAdsSpend,
    totalApplications,
    otherCurrencyAdsCount,
    totalAds,
    totalSpend,
    totalPostByProductDisplayNames,
    averageCostByProductDisplayNames,
  } = spendTabData;

  const numberFormatter = new Intl.NumberFormat(getLocale());

  if (hidePrice) {
    return (
      <Notice tone="info">
        <Text>{t('Information for hiding price')}</Text>
      </Notice>
    );
  }

  return (
    <Stack space="medium">
      <Box
        className={styles.spendContainer}
        paddingBottom={{ mobile: 'small', tablet: 'medium', desktop: 'none' }}
      >
        <Box display="flex" flexWrap="nowrap" gap="small">
          <Box className={styles.column}>
            <SpendCard
              id="total-spend"
              cost={totalSpend}
              description={t('N ads posted', {
                adCount: totalAds,
                adCountText: numberFormatter.format(totalAds),
                optionalAdType:
                  totalPostByProductDisplayNames.length === 1
                    ? ` ${totalPostByProductDisplayNames[0].type} `
                    : ' ',
              })}
              title={t('Total ad spend')}
              titleTooltip={<Text>{t('Total ad spend tooltip message')}</Text>}
            >
              {totalPostByProductDisplayNames.length > 1 ? (
                <Disclosure
                  size="xsmall"
                  expandLabel={t('Ad types posted')}
                  collapseLabel={t('Hide ad types posted')}
                  id="ad-usage-spend-tab-total-ad-spend"
                >
                  <Stack space="small">
                    {totalPostByProductDisplayNames.map(
                      ({ adsCount, type }) => (
                        <Text key={type} tone="secondary">
                          {t('N ads percentage', {
                            adsCount,
                            adCountText: numberFormatter.format(adsCount),
                            adType: type,
                            percentage: percentFromNumbersWithoutDecimal({
                              numerator: adsCount,
                              denominator: totalAds,
                            }),
                          })}
                        </Text>
                      ),
                    )}
                  </Stack>
                </Disclosure>
              ) : null}
            </SpendCard>
          </Box>
          <Box className={styles.column}>
            <SpendCard
              id="avg-cost-per-ad"
              cost={averageCostPerAd!}
              description={t('N applications per ad', {
                applications: averageApplications,
                applicationsText: numberFormatter.format(averageApplications),
                optionalAdType:
                  averageCostByProductDisplayNames.length === 1
                    ? ` ${averageCostByProductDisplayNames[0].type} `
                    : ' ',
              })}
              title={t('Avg. cost per ad')}
              titleTooltip={
                <Text>{t('Avg. cost per ad tooltip message')}</Text>
              }
            >
              {averageCostByProductDisplayNames.length > 1 ? (
                <Disclosure
                  size="xsmall"
                  expandLabel={t('Avg. cost by ad type')}
                  collapseLabel={t('Hide avg. cost by ad type')}
                  id="ad-usage-spend-tab-avg-cost-per-ad"
                >
                  <Stack space="small">
                    {averageCostByProductDisplayNames.map(
                      ({ cost, type }: AdTypeAverageCost) => (
                        <Text key={type} tone="secondary">
                          {/* @TODO: Replace with formatCurrency */}
                          {t('N cost per adtype', {
                            cost: formatMinorUnits(cost.value, {
                              locale: getLocale(),
                              currency: cost.currency,
                              display:
                                getSiteCurrency(site) !== cost.currency
                                  ? 'code'
                                  : 'narrowSymbol',
                              notation: 'standard',
                              dropFraction: cost.currency === 'IDR',
                            }),
                            adType: type,
                          })}
                        </Text>
                      ),
                    )}
                  </Stack>
                </Disclosure>
              ) : null}
            </SpendCard>
          </Box>
          <Box className={styles.column}>
            <SpendCard
              id="avg-cost-per-application"
              cost={averageCostPerApplication}
              description={t('N applications in total', {
                applications: totalApplications,
                applicationsText: numberFormatter.format(totalApplications),
              })}
              title={t('Avg. cost per application')}
              titleTooltip={t('Avg. cost per application tooltip message', {
                Stack: (child: ReactNodeNoStrings) => (
                  <Stack space="medium">{child}</Stack>
                ),
                Text: (child: ReactNodeNoStrings) => (
                  <Text key={child}>{child}</Text>
                ),
              })}
            />
          </Box>
          <Box className={styles.column}>
            <SpendCard
              id="repost-spend"
              cost={repostedAdsSpend}
              description={t('N reposted ads', {
                adsCount: repostedAdsCount,
                adsCountText: numberFormatter.format(repostedAdsCount),
                percentage: percentFromNumbersWithoutDecimal({
                  numerator: repostedAdsCount,
                  denominator: totalAds,
                }),
              })}
              title={t('Repost spend')}
              titleTooltip={t('Repost spend tooltip message', {
                Stack: (child: ReactNodeNoStrings) => (
                  <Stack space="medium">{child}</Stack>
                ),
                Text: (child: ReactNodeNoStrings) => (
                  <Text key={child}>{child}</Text>
                ),
              })}
            />
          </Box>
        </Box>
      </Box>
      {Boolean(otherCurrencyAdsCount) && (
        <Notice tone="info">
          <Text>
            {otherCurrencyAdsCount > 1
              ? t('The spend summary info', {
                  adsCount: numberFormatter.format(otherCurrencyAdsCount),
                })
              : t('The spend summary info for single ad')}
          </Text>
        </Notice>
      )}
    </Stack>
  );
};
