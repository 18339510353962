
      import { createLanguage, createTranslationFile } from '@vocab/webpack/web';
      
    const translations = createTranslationFile({
      "en": createLanguage(
        require.resolveWeak("./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-translations" */
          "./translations.json-en-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"th": createLanguage(
        require.resolveWeak("./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgcHJvZHVjdCB3aXRoIGRpc3BsYXkgbmFtZSI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSB7cHJvZHVjdERpc3BsYXlOYW1lfSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IuC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC5hOC4oeC5iOC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC4iuC4t%2BC5iOC4reC4muC4o%2BC4tOC4qeC4seC4lyIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiLguKXguIfguJvguKPguLDguIHguLLguKgge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC5gOC4lOC4t%2BC4reC4mX0gb25lIHvguYDguJTguLfguK3guJl9IG90aGVyIHvguYDguJTguLfguK3guJl9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlZpZXcgYWQiOiLguJTguLnguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-translations" */
          "./translations.json-th-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgcHJvZHVjdCB3aXRoIGRpc3BsYXkgbmFtZSI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSB7cHJvZHVjdERpc3BsYXlOYW1lfSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IuC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC5hOC4oeC5iOC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC4iuC4t%2BC5iOC4reC4muC4o%2BC4tOC4qeC4seC4lyIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiLguKXguIfguJvguKPguLDguIHguLLguKgge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC5gOC4lOC4t%2BC4reC4mX0gb25lIHvguYDguJTguLfguK3guJl9IG90aGVyIHvguYDguJTguLfguK3guJl9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlZpZXcgYWQiOiLguJTguLnguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"
        )
      ),
"id": createLanguage(
        require.resolveWeak("./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoie3Byb2R1Y3REaXNwbGF5TmFtZX0gQWQiLCJQcml2YXRlIEFkdmVydGlzZXIiOiJQZW5naWtsYW4gUHJpdmF0IiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aGFyaX0gb25lIHtoYXJpfSBvdGhlciB7aGFyaX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtidWxhbn0gb25lIHtidWxhbn0gb3RoZXIge2J1bGFufX0geWFuZyBsYWx1IiwiVmlldyBhZCI6Ik1lbGloYXQgaWtsYW4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-translations" */
          "./translations.json-id-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoie3Byb2R1Y3REaXNwbGF5TmFtZX0gQWQiLCJQcml2YXRlIEFkdmVydGlzZXIiOiJQZW5naWtsYW4gUHJpdmF0IiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aGFyaX0gb25lIHtoYXJpfSBvdGhlciB7aGFyaX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtidWxhbn0gb25lIHtidWxhbn0gb3RoZXIge2J1bGFufX0geWFuZyBsYWx1IiwiVmlldyBhZCI6Ik1lbGloYXQgaWtsYW4ifQ%3D%3D!"
        )
      ),
"zh": createLanguage(
        require.resolveWeak("./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-translations" */
          "./translations.json-zh-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-AU": createLanguage(
        require.resolveWeak("./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-AU-translations" */
          "./translations.json-en-AU-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-HK": createLanguage(
        require.resolveWeak("./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-HK-translations" */
          "./translations.json-en-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-ID": createLanguage(
        require.resolveWeak("./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-ID-translations" */
          "./translations.json-en-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-MY": createLanguage(
        require.resolveWeak("./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-MY-translations" */
          "./translations.json-en-MY-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-NZ": createLanguage(
        require.resolveWeak("./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-NZ-translations" */
          "./translations.json-en-NZ-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-PH": createLanguage(
        require.resolveWeak("./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-PH-translations" */
          "./translations.json-en-PH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-SG": createLanguage(
        require.resolveWeak("./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-SG-translations" */
          "./translations.json-en-SG-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-TH": createLanguage(
        require.resolveWeak("./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "en-TH-translations" */
          "./translations.json-en-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"th-TH": createLanguage(
        require.resolveWeak("./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgcHJvZHVjdCB3aXRoIGRpc3BsYXkgbmFtZSI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSB7cHJvZHVjdERpc3BsYXlOYW1lfSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IuC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC5hOC4oeC5iOC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC4iuC4t%2BC5iOC4reC4muC4o%2BC4tOC4qeC4seC4lyIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiLguKXguIfguJvguKPguLDguIHguLLguKgge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC5gOC4lOC4t%2BC4reC4mX0gb25lIHvguYDguJTguLfguK3guJl9IG90aGVyIHvguYDguJTguLfguK3guJl9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlZpZXcgYWQiOiLguJTguLnguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "th-TH-translations" */
          "./translations.json-th-TH-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiLguIjguLjguJTguILguLLguKLguKrguLPguITguLHguI0iLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoi4LmB4Liq4LiU4LiH4LmA4LiH4Li04LiZ4LmA4LiU4Li34Lit4LiZIiwiQmFkZ2UgcHJvZHVjdCB3aXRoIGRpc3BsYXkgbmFtZSI6IuC4m%2BC4o%2BC4sOC4geC4suC4qOC4h%2BC4suC4mSB7cHJvZHVjdERpc3BsYXlOYW1lfSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IuC4nOC4ueC5ieC4m%2BC4o%2BC4sOC4geC4reC4muC4geC4suC4o%2BC5hOC4oeC5iOC5gOC4m%2BC4tOC4lOC5gOC4nOC4ouC4iuC4t%2BC5iOC4reC4muC4o%2BC4tOC4qeC4seC4lyIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC4iuC4seC5iOC4p%2BC5guC4oeC4h30gb25lIHvguIrguLHguYjguKfguYLguKHguId9IG90aGVyIHvguIrguLHguYjguKfguYLguKHguId9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiLguKXguIfguJvguKPguLDguIHguLLguKgge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB74Lin4Lix4LiZfSBvbmUge%2BC4p%2BC4seC4mX0gb3RoZXIge%2BC4p%2BC4seC4mX194LiX4Li14LmI4LmB4Lil4LmJ4LinIiwiUG9zdGVkIFggbW9udGgocykgYWdvIjoi4Lil4LiH4Lib4Lij4Liw4LiB4Liy4LioIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge%2BC5gOC4lOC4t%2BC4reC4mX0gb25lIHvguYDguJTguLfguK3guJl9IG90aGVyIHvguYDguJTguLfguK3guJl9feC4l%2BC4teC5iOC5geC4peC5ieC4pyIsIlZpZXcgYWQiOiLguJTguLnguJvguKPguLDguIHguLLguKjguIfguLLguJkifQ%3D%3D!"
        )
      ),
"id-ID": createLanguage(
        require.resolveWeak("./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoie3Byb2R1Y3REaXNwbGF5TmFtZX0gQWQiLCJQcml2YXRlIEFkdmVydGlzZXIiOiJQZW5naWtsYW4gUHJpdmF0IiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aGFyaX0gb25lIHtoYXJpfSBvdGhlciB7aGFyaX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtidWxhbn0gb25lIHtidWxhbn0gb3RoZXIge2J1bGFufX0geWFuZyBsYWx1IiwiVmlldyBhZCI6Ik1lbGloYXQgaWtsYW4ifQ%3D%3D!"),
        () => import(
          /* webpackChunkName: "id-ID-translations" */
          "./translations.json-id-ID-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJOaWxhaS1uaWxhaSBqdWFsIHV0YW1hIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IkdhamkgZGl0YW1waWxrYW4iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoie3Byb2R1Y3REaXNwbGF5TmFtZX0gQWQiLCJQcml2YXRlIEFkdmVydGlzZXIiOiJQZW5naWtsYW4gUHJpdmF0IiwiUG9zdGVkIFggaG91cihzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7amFtfSBvbmUge2phbX0gb3RoZXIge2phbX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIGRheShzKSBhZ28iOiJEaXBvc3Rpbmcge3ZhbHVlLCBudW1iZXJ9IHt2YWx1ZSwgcGx1cmFsLCA9MCB7aGFyaX0gb25lIHtoYXJpfSBvdGhlciB7aGFyaX19IHlhbmcgbGFsdSIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IkRpcG9zdGluZyB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtidWxhbn0gb25lIHtidWxhbn0gb3RoZXIge2J1bGFufX0geWFuZyBsYWx1IiwiVmlldyBhZCI6Ik1lbGloYXQgaWtsYW4ifQ%3D%3D!"
        )
      ),
"zh-HK": createLanguage(
        require.resolveWeak("./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"),
        () => import(
          /* webpackChunkName: "zh-HK-translations" */
          "./translations.json-zh-HK-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJLZXkgc2VsbGluZyBwb2ludHMiLCJCYWRnZSBzYWxhcnkgZGlzcGxheWVkIjoiU2FsYXJ5IGRpc3BsYXllZCIsIkJhZGdlIHByb2R1Y3Qgd2l0aCBkaXNwbGF5IG5hbWUiOiJ7cHJvZHVjdERpc3BsYXlOYW1lfSBhZCIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlByaXZhdGUgQWR2ZXJ0aXNlciIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiUG9zdGVkIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUsIHBsdXJhbCwgPTAge2hvdXJ9IG9uZSB7aG91cn0gb3RoZXIge2hvdXJzfX0gYWdvIiwiUG9zdGVkIFggZGF5KHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHtkYXl9IG9uZSB7ZGF5fSBvdGhlciB7ZGF5c319IGFnbyIsIlBvc3RlZCBYIG1vbnRoKHMpIGFnbyI6IlBvc3RlZCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLCBwbHVyYWwsID0wIHttb250aH0gb25lIHttb250aH0gb3RoZXIge21vbnRoc319IGFnbyIsIlZpZXcgYWQiOiJWaWV3IGFkIn0%3D!"
        )
      ),
"en-PSEUDO": createLanguage(
        require.resolveWeak("./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJb4biw4bq94bq94bq9w73DvcO9IMWh4bq94bq94bq9xprGmsOsw6zDrOC4geC4teC5icSjIMalw7bDtsO2w6zDrMOs4LiB4Li14LmJ4bmvxaFdIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IlvFoMSDxIPGmsSDxIPFmcO9w70gxozDrMOsxaHGpcaaxIPEg8O9w73hur3hur3GjF0iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoiW3twcm9kdWN0RGlzcGxheU5hbWV9IMSDxIPEg8aMXSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlvGpMWZw6zDrMOs4bm9xIPEg8SD4bmv4bq94bq94bq9IOG6rMaM4bm94bq94bq94bq9xZnhua%2FDrMOsw6zFoeG6veG6veG6vcWZXSIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiW8akw7bDtsO2xaHhua%2Fhur3hur3hur3GjCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvhuKnDtsO2x5rHmsWZfSBvbmV74bipw7bDtseax5rFmX0gb3RoZXJ74bipw7bDtseax5rFmcWhfX0gxIPEg8Sjw7bDtl0iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiW8akw7bDtsO2xaHhua%2Fhur3hur3hur3GjCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvGjMSDxIPDvcO9fSBvbmV7xozEg8SDw73DvX0gb3RoZXJ7xozEg8SDw73DvcWhfX0gxIPEg8Sjw7bDtl0iLCJQb3N0ZWQgWCBtb250aChzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r%2BG6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we23MgsO2w7bDtuC4geC4teC5ieG5r%2BG4qX0gb25le23MgsO2w7bDtuC4geC4teC5ieG5r%2BG4qX0gb3RoZXJ7bcyCw7bDtsO2w7bguIHguLXguYnhua%2FhuKnFoX19IMSDxIPEo8O2w7ZdIiwiVmlldyBhZCI6IlvhubzDrMOs4bq94bq9xbUgxIPEg8aMXSJ9!"),
        () => import(
          /* webpackChunkName: "en-PSEUDO-translations" */
          "./translations.json-en-PSEUDO-virtual.json!=!../../../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?source=eyJCYWRnZSBrZXkgc2VsbGluZyBwb2ludHMiOiJb4biw4bq94bq94bq9w73DvcO9IMWh4bq94bq94bq9xprGmsOsw6zDrOC4geC4teC5icSjIMalw7bDtsO2w6zDrMOs4LiB4Li14LmJ4bmvxaFdIiwiQmFkZ2Ugc2FsYXJ5IGRpc3BsYXllZCI6IlvFoMSDxIPGmsSDxIPFmcO9w70gxozDrMOsxaHGpcaaxIPEg8O9w73hur3hur3GjF0iLCJCYWRnZSBwcm9kdWN0IHdpdGggZGlzcGxheSBuYW1lIjoiW3twcm9kdWN0RGlzcGxheU5hbWV9IMSDxIPEg8aMXSIsIlByaXZhdGUgQWR2ZXJ0aXNlciI6IlvGpMWZw6zDrMOs4bm9xIPEg8SD4bmv4bq94bq94bq9IOG6rMaM4bm94bq94bq94bq9xZnhua%2FDrMOsw6zFoeG6veG6veG6vcWZXSIsIlBvc3RlZCBYIGhvdXIocykgYWdvIjoiW8akw7bDtsO2xaHhua%2Fhur3hur3hur3GjCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvhuKnDtsO2x5rHmsWZfSBvbmV74bipw7bDtseax5rFmX0gb3RoZXJ74bipw7bDtseax5rFmcWhfX0gxIPEg8Sjw7bDtl0iLCJQb3N0ZWQgWCBkYXkocykgYWdvIjoiW8akw7bDtsO2xaHhua%2Fhur3hur3hur3GjCB7dmFsdWUsIG51bWJlcn0ge3ZhbHVlLHBsdXJhbCw9MHvGjMSDxIPDvcO9fSBvbmV7xozEg8SDw73DvX0gb3RoZXJ7xozEg8SDw73DvcWhfX0gxIPEg8Sjw7bDtl0iLCJQb3N0ZWQgWCBtb250aChzKSBhZ28iOiJbxqTDtsO2w7bFoeG5r%2BG6veG6veG6vcaMIHt2YWx1ZSwgbnVtYmVyfSB7dmFsdWUscGx1cmFsLD0we23MgsO2w7bDtuC4geC4teC5ieG5r%2BG4qX0gb25le23MgsO2w7bDtuC4geC4teC5ieG5r%2BG4qX0gb3RoZXJ7bcyCw7bDtsO2w7bguIHguLXguYnhua%2FhuKnFoX19IMSDxIPEo8O2w7ZdIiwiVmlldyBhZCI6IlvhubzDrMOs4bq94bq9xbUgxIPEg8aMXSJ9!"
        )
      )
      });
  
      export { translations as default };
    