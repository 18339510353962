import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { orderBy } from 'lodash';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { TipOption } from 'src/types/AdUsageFiltersResponse';
import type { AdTipType } from 'src/types/AdUsageTypes';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import { getOptionLabel } from '../../Filters.formatters';

import translations from './.vocab';

export interface TipsToImproveAdsProps {
  tips: TipOption[];
}

export const TipsToImproveAdsFilter = ({ tips }: TipsToImproveAdsProps) => {
  const { t } = useTranslations(translations);
  const {
    adUsageFilters: { adTips: selectedTips },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const {
    setIsFilterTipsToImproveAds,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAddOns,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const typedValue = value as AdTipType;
    const newSelected = selectedTips.includes(typedValue)
      ? [
          ...selectedTips.filter(
            (singleSelected) => singleSelected !== typedValue,
          ),
        ]
      : [...selectedTips, typedValue];

    trackEvent('filter_pressed', {
      isFilterTipsToImproveAds: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAddOns,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad tips',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_TIPS_TO_IMPROVE_ADS,
      value: newSelected,
    });

    setIsFilterTipsToImproveAds(newSelected.length > 0);
  };

  const formattedTips = tips.map((data) => ({
    ...data,
    value: t(data.key),
  }));

  const orderedTips = orderBy(formattedTips, ['value'], ['asc']);

  const displayedValue = getOptionLabel(
    orderedTips,
    selectedTips,
    t,
    t('All ads'),
  );

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_TIPS_TO_IMPROVE_ADS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={orderedTips}
      label={t('Tips to improve ads')}
      selected={selectedTips}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      clearSelection={clearSelection}
    />
  );
};
