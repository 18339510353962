import { Box, BraidPortal } from 'braid-design-system';
import { atoms } from 'braid-design-system/css';
import { useEffect, useState, type ReactNode } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import * as styles from './disclosureDialogNew.css';

export type DisclosureContentArrowProps = ReturnType<
  ReturnType<typeof usePopperTooltip>['getArrowProps']
>;

interface DisclosureTriggerProps {
  ref: ReturnType<typeof usePopperTooltip>['setTooltipRef'];
  tabIndex: 0;
  'data-id': string;
  'aria-describedby': string;
  className?: string;
}

export interface DisclosureContentProps {
  children: ReactNodeNoStrings;
  opacity: 0 | 100;
  arrowProps: DisclosureContentArrowProps;
}

export const DisclosureContent = ({
  children,
  opacity,
  arrowProps,
}: DisclosureContentProps) => (
  <Box
    display="flex"
    position="relative"
    transition="fast"
    opacity={opacity === 0 ? 0 : undefined}
    className={
      opacity === 0 ? styles.verticalOffsetBeforeEntrance : styles.translateZ0
    }
  >
    <Box
      className={[styles.disclosureContent, styles.translateZ0]}
      background="surface"
      borderRadius="large"
      padding="medium"
    >
      <Box position="relative" zIndex={1}>
        {children}
      </Box>
      <Box
        {...arrowProps}
        background="surface"
        borderRadius="large"
        className={styles.arrow}
      />
    </Box>
  </Box>
);

type Placement = 'top' | 'bottom' | 'left' | 'right';

export interface DisclosureDialogProps {
  id: string;
  tooltip: ReactNodeNoStrings;
  placement?: Placement;
  children: (renderProps: {
    triggerProps: DisclosureTriggerProps;
  }) => ReactNode;
}

export const DisclosureDialog = ({
  id,
  tooltip,
  placement,
  children,
}: DisclosureDialogProps) => {
  const [controlledVisible, setControlledVisible] = useState(false);
  const [opacity, setOpacity] = useState<0 | 100>(100);

  const {
    visible,
    getTooltipProps,
    setTooltipRef,
    tooltipRef,
    setTriggerRef,
    triggerRef,
    getArrowProps,
  } = usePopperTooltip({
    placement,
    trigger: 'click',
    visible: controlledVisible,
    onVisibleChange: setControlledVisible,
  });

  useEffect(() => {
    if (visible) {
      const handleKeyDown = ({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
          setControlledVisible(false);
        }
      };

      const handleScroll = () => {
        setControlledVisible(false);
      };

      const scrollHandlerOptions = {
        capture: true,
        passive: true,
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('scroll', handleScroll, scrollHandlerOptions);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener(
          'scroll',
          handleScroll,
          scrollHandlerOptions,
        );
      };
    }
  }, [visible]);

  useEffect(() => {
    if (!tooltipRef || !visible) {
      return setOpacity(0);
    }

    setOpacity(100);
  }, [tooltipRef, visible]);

  return (
    <>
      {children({
        triggerProps: {
          tabIndex: 0,
          ref: setTriggerRef,
          'data-id': `${id}-trigger`,
          'aria-describedby': id,
          className: styles.disclosureTrigger,
        },
      })}

      {triggerRef && (
        <BraidPortal>
          <div
            id={id}
            data-id={id}
            role="tooltip"
            hidden={!visible ? true : undefined}
            className={atoms({
              reset: 'div',
              zIndex: 'notification',
              display: triggerRef && visible ? undefined : 'none',
            })}
            {...(visible
              ? getTooltipProps({
                  ref: setTooltipRef,
                })
              : null)}
          >
            <DisclosureContent opacity={opacity} arrowProps={getArrowProps()}>
              {tooltip}
            </DisclosureContent>
          </div>
        </BraidPortal>
      )}
    </>
  );
};
