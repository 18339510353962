import { useQuery } from '@apollo/client';

import { useConfig } from 'src/App/ConfigContext';
import { similarAdsQuery } from 'src/queries/similarAdsQuery';

export const useSimilarAds = ({ jobId = '' }: { jobId: string }) => {
  const { locale } = useConfig();
  const { data, loading, error, previousData } = useQuery(similarAdsQuery, {
    variables: {
      input: {
        jobId,
        locale,
      },
    },
    errorPolicy: 'all',
  });

  return {
    data: (loading && previousData ? previousData : data)
      ?.analyticsSimilarAdsDetails,
    isLoading: loading,
    error,
  };
};
