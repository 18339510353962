import { type SiteName, siteNames } from '@seek/melways-sites';
import {
  getScriptTag,
  adapter as createTealiumAdapter,
} from '@seek/tealium-adapter';

import type { AdReportDataLayer } from '../types/TealiumDataLayer';

let tealiumAdapter: TealiumAdapter | undefined;
let pageLoaded = false;
let widgetViewcalled = false;

export const IS_REGISTERED_WIDGET_LOADED: Record<string, boolean> = {
  Recommendations: false,
  AdRating: false,
};

export const getTrackerScriptTag = (
  environment: 'prod' | 'dev' | 'qa',
): string => {
  const preventViewOnLoad =
    '<script>window.utag_cfg_ovrd = { noview: true };</script>';

  const compositeTagString = `${preventViewOnLoad}${getScriptTag({
    account: 'seek',
    profile: 'hirer',
    environment,
  })}`;

  return compositeTagString.replace('async', 'defer');
};

export const initTealiumTracker = () => {
  tealiumAdapter = createTealiumAdapter(undefined);
};

export const setTrackerData = (newDataLayer: AdReportDataLayer) => {
  window.adReportDataLayer = newDataLayer;
};

export const getTrackerData = ():
  | AdReportDataLayer
  | Record<string, unknown> =>
  typeof window !== 'undefined' ? window.adReportDataLayer : {};

export const getAnalyticsData: AdReportDataLayer =
  getTrackerData() as AdReportDataLayer;

/**
 * Initialize ad_performance_report_displayed event only once on the APR page
 * @param data
 */
export const trackAPRPage = (data: AdReportDataLayer) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }

  setTrackerData(data);
  if (!pageLoaded) {
    tealiumAdapter.view('ad_performance_report_displayed', data);
    pageLoaded = true;
  }
};

/**
 * Initialize widget-view event when all required widgets are loaded
 * @param data
 * @param widgetName
 */
export const trackWidgetView = (
  data: AdReportDataLayer,
  widgetName: string,
) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  setTrackerData(data);

  // trackWidgetView is triggered after widget is loaded,
  // so it is always true
  IS_REGISTERED_WIDGET_LOADED[widgetName] = true;

  const isAllWidgetsLoaded = Object.keys(IS_REGISTERED_WIDGET_LOADED).filter(
    (key) => !IS_REGISTERED_WIDGET_LOADED[key],
  );

  // make sure event is triggered only once
  if (!widgetViewcalled && !isAllWidgetsLoaded.length) {
    tealiumAdapter.view('widgets_displayed', data);
    widgetViewcalled = true;
  }
};

/**
 * Triggered by user's mouse event click or scroll
 * @param eventName
 * @param props
 */
export const trackEvent = <T>(eventName: string, props: T) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  const dataLayer = getTrackerData();
  tealiumAdapter.view(eventName, { ...dataLayer, ...props });
};

/**
 * Triggered by clicking on a link
 * @param eventName
 * @param props
 */
export const trackLink = <T>(eventName: string, props: T) => {
  if (!tealiumAdapter) {
    throw new Error('Tealium adapter needs to be initialised');
  }
  tealiumAdapter.link(eventName, props);
};

export const isANZSite = (siteName: SiteName) =>
  [siteNames.employer.seek.au, siteNames.employer.seek.nz].some(
    (element) => element === siteName,
  );

export const isJobstreetSite = (siteName: SiteName) =>
  [
    siteNames.employer.seek.my,
    siteNames.employer.seek.sg,
    siteNames.employer.seek.ph,
    siteNames.employer.seek.id,
  ].some((element) => element === siteName);

export const isJobsdbSite = (siteName: SiteName) =>
  [siteNames.employer.seek.hk, siteNames.employer.seek.th].some(
    (element) => element === siteName,
  );

export const getBrandFromSiteName = (siteName: SiteName) => {
  if (isANZSite(siteName)) {
    return 'seek';
  }

  if (isJobstreetSite(siteName)) {
    return 'jobstreet';
  }

  if (isJobsdbSite(siteName)) {
    return 'jobsdb';
  }

  return 'seek';
};
