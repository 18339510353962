import { gql } from '@apollo/client';

export const myJobInformationQuery = gql`
  query GetMyJobInformationData($input: MyJobInformationWidgetInput!) {
    analyticsMyJobInformation(input: $input) {
      addOns
      advertiserName
      applyUrl
      classificationName
      createdByName
      createdByEmail
      isLinkOut
      isPrivateAdvertiser
      isSalaryHidden
      jobCreatedTimestampUTC
      jobLocationLabel
      jobTitle
      latestEdit {
        name
        email
        timestampUTC
      }
      lastUpdatedTimestampUTC
      mirrorClassificationName
      mirrorSubClassificationName
      postTypeCategory
      analyticsProductDisplayName
      salary {
        additionalText
        currency
        maximum
        minimum
        type
      }
      subClassificationName
      workType
    }
  }
`;
