import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { AddOnsOption } from 'src/types/AdUsageFiltersResponse';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import { getOptionLabel } from '../Filters.formatters';

import translations from './.vocab';

export interface AddOnsFilterProps {
  addOns: AddOnsOption[];
}

export const AddOnsFilter = ({ addOns }: AddOnsFilterProps) => {
  const { t } = useTranslations(translations);

  const {
    adUsageFilters: { addOns: selectedAddOns },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();

  const {
    setIsFilterAddOns,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const newSelected: string[] = selectedAddOns.includes(value)
      ? [...selectedAddOns.filter((singleSelected) => singleSelected !== value)]
      : [...selectedAddOns, value].filter(Boolean);

    trackEvent('filter_pressed', {
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAddOns: newSelected.length > 0,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad type',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_ADD_ONS,
      value: newSelected,
    });
    setIsFilterAddOns(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_ADD_ONS,
      value: [],
    });

  const formattedAddOns = addOns.map((data) => ({
    ...data,
    value: data.key === 'NO_ADD_ONS' ? t('No add-ons') : data.key,
  }));

  const displayedValue = getOptionLabel(
    formattedAddOns,
    selectedAddOns,
    t,
    t('All add-ons'),
  );

  return (
    <MultiSelectDropdown
      options={formattedAddOns}
      label={t('Add-ons')}
      selected={selectedAddOns}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      searchPlaceholder="search"
      clearSelection={clearSelection}
    />
  );
};
