import { useHirerAuth } from '@seek/hirer-auth-react';
import { useAccountContext } from '@seek/hirer-graphql-react';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Button,
  Dialog,
  Heading,
  IconDownload,
  Stack,
  Text,
} from 'braid-design-system';
import { useContext, useState } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { Illustrations } from 'src/assets/icons/Illustrations/Illustrations';
import { ContentBlock } from 'src/components/ContentBlock/ContentBlock';
import { ErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary';
import { getExportUrl, isLocal } from 'src/config';
import { IsFilterContext } from 'src/context/IsFilterContext';
import { useAdUsageQueryFilters } from 'src/context/adUsageFilters';
import { downloadFile } from 'src/utils/downloadFile';
import LoggerProvider from 'src/utils/logger/logger';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';
import { ChildAccountBanner } from 'src/widgets/ChildAccountBanner/ChildAccountBanner';

import translations from './.vocab';

export interface AdUsageHeaderProps {
  hasAccess: boolean;
  numOfAds?: number;
}

export const AdUsageHeader = ({ hasAccess, numOfAds }: AdUsageHeaderProps) => {
  const showExportButton = Boolean(numOfAds);
  const { locale } = useConfig();
  const filters = useAdUsageQueryFilters();
  const { getToken } = useHirerAuth();
  const { t } = useTranslations(translations);
  const [isDownloading, setIsDownloading] = useState(false);
  const [hasExportError, setHasExportError] = useState(false);
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAddOns,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const { accountContext } = useAccountContext();

  const advertiserName =
    accountContext?.headerFooterContext?.advertiser?.name || 'SEEK';
  const accountNumber =
    accountContext?.headerFooterContext?.advertiser?.billingId || '';

  const getExportHeaders = async () => {
    if (isLocal()) {
      const email = 'seekUsername@seek.com.au';
      const localHeaders = {
        'User-Id': email,
        'X-Seek-Oidc-Identity': JSON.stringify({
          sub: {
            ID: email,
            email,
            email_verified: true,
            country: 'AU',
            brand: 'seek',
            experience: 'hirer',
          },
        }),
        'Advertiser-Id': '28829855',
        'Content-Type': 'application/json',
        Authorization: `Bearer yourToken`,
      };
      return localHeaders;
    }

    const token = await getToken();

    return {
      Authorization: token!,
      'Content-Type': 'application/json',
    };
  };

  const onDownloadReportClick = async () => {
    setIsDownloading(true);
    try {
      const headers = await getExportHeaders();
      trackEvent('export_pressed', {
        objectInteraction: 'export-interacted',
        isFilterAdPerformance,
        isFilterAdType,
        isFilterClassification,
        isFilterJobTitle,
        isFilterLocation,
        isFilterAccountHierachy,
        isFilterAddOns,
        isFilterAdId,
        isFilterAdStatus,
        isFilterRepost,
        isFilterTipsToImproveAds,
        isFilterUser,
        isFilterBudget,
      });
      const exportUrl = getExportUrl();
      const response = await fetch(exportUrl, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers,
        body: JSON.stringify({
          locale,
          filters,
          numOfAds,
          timezone,
          isPerformanceRatingEnabled: true,
          isAdBudgetFilterEnabled: true,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error downloading CSV, status: ${response.status}`);
      }

      const fileName =
        response.headers
          .get('content-disposition')
          ?.split(';')[1]
          .split('filename=')[1]
          .replaceAll('"', '') || 'export.csv';

      const blob = await response.blob();
      await downloadFile(blob, decodeURI(fileName));

      trackEvent('export_succeeded', {
        objectInteraction: 'export_succeeded',
        isFilterAdPerformance,
        isFilterAdType,
        isFilterClassification,
        isFilterJobTitle,
        isFilterLocation,
        isFilterAccountHierachy,
        isFilterAddOns,
        isFilterAdId,
        isFilterAdStatus,
        isFilterRepost,
        isFilterTipsToImproveAds,
        isFilterUser,
        isFilterBudget,
      });
    } catch (err: any) {
      LoggerProvider.logger.error(err.message, {
        error: err,
      });
      setHasExportError(true);
    } finally {
      setIsDownloading(false);
    }
  };

  const AdUsageTitle = () => <Heading level="3">{t('Ad usage title')}</Heading>;

  return (
    <Box background="surface" paddingX="small">
      <ContentBlock>
        <Box
          alignItems={{ mobile: 'flexStart', tablet: 'center' }}
          display="flex"
          flexDirection={{ mobile: 'column', tablet: 'row' }}
          justifyContent="spaceBetween"
          paddingY="medium"
          gap="medium"
        >
          <Stack space="gutter">
            <ErrorBoundary fallback={<AdUsageTitle />}>
              <AdUsageTitle />
              <ChildAccountBanner
                advertiserName={advertiserName}
                accountNumber={accountNumber}
              />
            </ErrorBoundary>
          </Stack>
          {hasAccess && showExportButton && (
            <Box>
              <Button
                icon={<IconDownload />}
                onClick={onDownloadReportClick}
                loading={isDownloading}
                variant="ghost"
                tone="neutral"
              >
                {isDownloading ? t('Preparing download') : t('Download report')}
              </Button>
            </Box>
          )}
          <Dialog
            title=""
            id="ad-usage-export-error-message"
            open={hasExportError}
            onClose={() => setHasExportError(false)}
          >
            <Box background="surface">
              <Box textAlign="center" paddingBottom="medium">
                <Illustrations />
              </Box>
              <Stack space="medium">
                <Heading level="2" align="center">
                  {t("Can't download report")}
                </Heading>
                <Text align="center">
                  {t('Refresh the page or try again later')}
                </Text>
              </Stack>
            </Box>
          </Dialog>
        </Box>
      </ContentBlock>
    </Box>
  );
};
