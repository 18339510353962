import { useTranslations } from '@vocab/react';
import {
  Box,
  type ButtonIcon,
  Dialog,
  IconInfo,
  Text,
  TextLink,
  TooltipRenderer,
} from 'braid-design-system';
import { type ComponentProps, type ReactNode, useState } from 'react';
import { HashLink } from 'react-router-hash-link';

import translations from './.vocab';

import * as styles from './InlineTooltip.css';

// Inherited from Button.tsx and represents the 'transparent' variant
export type ButtonTone = 'formAccent' | 'brandAccent' | 'critical' | 'neutral';

interface ButtonStyles {
  textTone: ComponentProps<typeof Text>['tone'];
  background: ComponentProps<typeof Box>['background'];
  backgroundHover: ComponentProps<typeof Box>['background'];
  backgroundActive: ComponentProps<typeof Box>['background'];
  boxShadow: ComponentProps<typeof Box>['boxShadow'] | undefined;
}

const variants: Record<ButtonTone, ButtonStyles> = {
  formAccent: {
    textTone: 'formAccent',
    background: undefined,
    backgroundHover: 'formAccentSoftHover',
    backgroundActive: 'formAccentSoftActive',
    boxShadow: undefined,
  },
  brandAccent: {
    textTone: 'brandAccent',
    background: undefined,
    backgroundHover: 'brandAccentSoftHover',
    backgroundActive: 'brandAccentSoftActive',
    boxShadow: undefined,
  },
  critical: {
    textTone: 'critical',
    background: undefined,
    backgroundHover: 'criticalSoftHover',
    backgroundActive: 'criticalSoftActive',
    boxShadow: undefined,
  },
  neutral: {
    textTone: 'neutral',
    background: undefined,
    backgroundHover: 'neutralSoftHover',
    backgroundActive: 'neutralSoftActive',
    boxShadow: undefined,
  },
};

export interface InlineTooltipProps {
  id: string;
  title: string;
  contents: ReactNode;
  size?: React.ComponentProps<typeof ButtonIcon>['size'];
  tone?: ButtonTone;
  hashLinkProps?: React.ComponentProps<typeof HashLink>;
  hashLinkLabel?: string;
}

export const InlineTooltip = ({
  id,
  title,
  contents,
  tone = 'neutral',
  hashLinkProps,
  hashLinkLabel,
}: InlineTooltipProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslations(translations);
  let buttonRef: HTMLElement | null = null;

  const handleHashlinkClick = () => {
    setOpen(false);
  };

  const Overlay = ({ width }: { width: number }) => (
    <>
      <Box
        position="absolute"
        style={{
          top: 0,
          left: '50%',
          width,
          height: width,
          transform: 'translateX(-50%)',
        }}
        background={variants[tone].backgroundHover}
        borderRadius="full"
        opacity={0}
        transition="fast"
        className={styles.hoverOverlay}
      />
      <Box
        position="absolute"
        style={{
          top: 0,
          left: '50%',
          width,
          height: width,
          transform: 'translateX(-50%)',
        }}
        boxShadow="outlineFocus"
        borderRadius="full"
        opacity={0}
        transition="fast"
        className={styles.focusOverlay}
      />
    </>
  );

  return (
    <>
      <TooltipRenderer
        id={`${id}-tooltip`}
        tooltip={<Text>{t('Click to learn more')}</Text>}
      >
        {({ triggerProps: { ref: triggerRef, ...triggerProps } }) => (
          <Box
            aria-label={t('Click to learn more')}
            component="button"
            position="relative"
            display="inline"
            role="button"
            cursor="pointer"
            zIndex={0}
            outline="none"
            maxWidth="content"
            onClick={() => {
              setOpen(true);
            }}
            className={styles.root}
            ref={(node) => {
              buttonRef = node;
              triggerRef(node);
            }}
            {...triggerProps}
          >
            <Overlay
              width={buttonRef ? buttonRef.getBoundingClientRect().height : 0}
            />
            <Box position="relative" zIndex={1}>
              <IconInfo tone={tone} />
            </Box>
          </Box>
        )}
      </TooltipRenderer>
      <Dialog
        id={`inline-tooltip-${id}-dialog`}
        title={title}
        open={open}
        closeLabel={t('Close')}
        onClose={() => {
          setOpen(false);
        }}
      >
        {contents}
        {hashLinkProps && (
          <Text>
            <TextLink href="">
              <HashLink
                className={styles.hashLink}
                {...hashLinkProps}
                onClick={handleHashlinkClick}
              >
                {hashLinkLabel}
              </HashLink>
            </TextLink>
          </Text>
        )}
      </Dialog>
    </>
  );
};
