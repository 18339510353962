import {
  MultiSelectDropdown,
  type MultiSelectDropdownOption,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { IconInfo, Stack, Text } from 'braid-design-system';
import { useContext } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { LAUNCH_DATES } from 'src/config';
import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { BudgetOption } from 'src/types/AdUsageFiltersResponse';
import { formatDateWithUnifiedDisplay, isDateBefore } from 'src/utils/date';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';
import { getDisplayValue } from 'src/widgets/Filters/Filters.formatters';

import translations from './.vocab';
import { buildBudgetFilterData } from './BudgetFilter.formatters';

interface BudgetFilterProps {
  budgets: BudgetOption[];
  enableLazyLoading?: boolean;
}

const BudgetFilterItem = (
  option: MultiSelectDropdownOption<BudgetOption['data']>,
  checkboxStatus: boolean | 'mixed',
) => {
  const { t } = useTranslations(translations);
  const { locale } = useConfig();

  return (
    <Stack space="small">
      <Text
        maxLines={2}
        tone="secondary"
        weight={checkboxStatus !== false ? 'strong' : undefined}
      >
        {option.value}
      </Text>
      {option.key !== 'Other' ? (
        <Stack space="xsmall">
          <Text tone="secondary" size="small">
            {t('Contract no. contractNo', { contractNo: option.key })}
          </Text>
          {option.data?.expiryTimestampUTC ? (
            <Text tone="secondary" size="small">
              {t(
                isDateBefore(
                  new Date(),
                  new Date(option.data.expiryTimestampUTC),
                )
                  ? 'Valid until date'
                  : 'Expired on date',
                {
                  date: formatDateWithUnifiedDisplay({
                    date: new Date(option.data.expiryTimestampUTC),
                    locale,
                  }),
                },
              )}
            </Text>
          ) : null}
        </Stack>
      ) : null}
    </Stack>
  );
};

const BudgetFilter = ({ budgets, enableLazyLoading }: BudgetFilterProps) => {
  const { t } = useTranslations(translations);
  const { locale, site } = useConfig();

  const {
    adUsageFilters: { budgetIds: selectedBudgets },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();

  const budgetData = buildBudgetFilterData(budgets, t);

  const displayedValue = getDisplayValue(
    selectedBudgets,
    {},
    budgetData,
    t,
    t('All budgets'),
  );

  const {
    setIsFilterBudget,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAddOns,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const newSelected = selectedBudgets.includes(value)
      ? [
          ...selectedBudgets.filter(
            (singleSelected) => singleSelected !== value,
          ),
        ]
      : [...selectedBudgets, value];

    trackEvent('filter_pressed', {
      isFilterBudget: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAddOns,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      filterType: 'budget',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_BUDGET_IDS,
      value: newSelected,
    });

    setIsFilterBudget(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_BUDGET_IDS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={budgetData}
      label={t('Ad budget')}
      selected={selectedBudgets}
      value={displayedValue}
      valueRenderer={BudgetFilterItem}
      onSelect={onSelect}
      lazyLoadingEnabled={enableLazyLoading}
      clearSelection={clearSelection}
      searchable={false}
      disclaimer={
        LAUNCH_DATES[site] ? (
          <Text tone="info" icon={<IconInfo />}>
            {t(
              "This report doesn't include ads purchased without an Ad Budget before {date}",
              {
                date: formatDateWithUnifiedDisplay({
                  date: new Date(LAUNCH_DATES[site]),
                  locale,
                }),
              },
            )}
          </Text>
        ) : null
      }
    />
  );
};

export { BudgetFilter, type BudgetFilterProps };
