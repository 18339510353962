import { useTranslations } from '@vocab/react';
import { Box, Text, Badge } from 'braid-design-system';
import type { ReactNode } from 'react';

import type { AdRatingType } from 'src/types/AdUsageTypes';

import { ClickableTooltip } from '../ClickableTooltip/ClickableTooltip';

import translations from './.vocab';

export interface AdRatingLabelProps {
  adRating: AdRatingType;
  onMouseEnterRating: () => void;
  onMouseLeaveRating: () => void;
  jobId: string;
  hoveredGroupKey: string | undefined;
}
export const AdRatingLabel = ({
  adRating,
  onMouseEnterRating,
  onMouseLeaveRating,
  jobId,
  hoveredGroupKey,
}: AdRatingLabelProps) => {
  const { t } = useTranslations(translations);

  const TooltipLabel = ({
    label,
    tooltipTitle,
    tooltip,
  }: {
    label: string;
    tooltipTitle: string;
    tooltip: ReactNode;
  }) => (
    <Box
      position="relative"
      zIndex={hoveredGroupKey === jobId ? 'dropdownBackdrop' : 1}
      onMouseEnter={onMouseEnterRating}
      onMouseLeave={onMouseLeaveRating}
    >
      <Box
        display="flex"
        gap="xsmall"
        alignItems="center"
        justifyContent="flexStart"
        flexWrap="nowrap"
      >
        <Text size="small" maxLines={1}>
          {label}
        </Text>
        <ClickableTooltip
          id={`${label}-tooltip`}
          title={tooltipTitle}
          contents={<Text>{tooltip}</Text>}
        />
      </Box>
    </Box>
  );

  const components = {
    LOW: <Badge tone="critical">{t('Low')}</Badge>,
    NORMAL: <Badge tone="info">{t('Normal')}</Badge>,
    HIGH: <Badge tone="positive">{t('High')}</Badge>,
    TOO_SOON_TO_RATE: (
      <TooltipLabel
        label={t('No rating yet')}
        tooltipTitle={t('No rating yet')}
        tooltip={t('It is too early to see how your ad is doing.')}
      />
    ),
    UNABLE_TO_RATE: (
      <TooltipLabel
        label={t('Unable to rate')}
        tooltipTitle={t('Unable to rate')}
        tooltip={t('There is not enough information')}
      />
    ),
    UNAVAILABLE: (
      <TooltipLabel
        label={t('Unavailable')}
        tooltipTitle={t('Unavailable performance')}
        tooltip={t(
          'Only job ads posted on the new website have performance reports.',
        )}
      />
    ),
    NOT_INCLUDED: (
      <TooltipLabel
        label={t('Not included')}
        tooltipTitle={t('Not included')}
        tooltip={t('Lite Ads performance explanation')}
      />
    ),
  };

  return components[adRating];
};
