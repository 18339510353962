import { MultiSelectDropdown } from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { orderBy } from 'lodash';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { AdStatus } from 'src/types/AdPerformanceTypes';
import type { AdStatusOption } from 'src/types/AdUsageFiltersResponse';
import { trackEvent } from 'src/utils/tealiumAUREventTracker';

import { getOptionLabel } from '../../Filters.formatters';

import translations from './.vocab';

export interface AdStatusFilterProps {
  adStatus: AdStatusOption[];
}

export const AdStatusFilter = ({ adStatus }: AdStatusFilterProps) => {
  const { t } = useTranslations(translations);
  const {
    adUsageFilters: { adStatuses: selectedAdStatus },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const {
    setIsFilterAdStatus,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterJobTitle,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAddOns,
    isFilterAdId,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (value: string) => {
    const typedValue = value as AdStatus;
    const newSelected = selectedAdStatus.includes(typedValue)
      ? [
          ...selectedAdStatus.filter(
            (singleSelected) => singleSelected !== typedValue,
          ),
        ]
      : [...selectedAdStatus, typedValue];

    trackEvent('filter_pressed', {
      isFilterAdStatus: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterJobTitle,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAddOns,
      isFilterAdId,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'ad status',
      filterMethod: 'neither',
    });

    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_STATUS,
      value: newSelected,
    });
    setIsFilterAdStatus(newSelected.length > 0);
  };

  const formattedAdStatus = orderBy(adStatus, ['key'], ['asc']).map((data) => ({
    ...data,
    value: t(data.key),
  }));

  const displayedValue = getOptionLabel(
    formattedAdStatus,
    selectedAdStatus,
    t,
    t('All ad status'),
  );

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_AD_STATUS,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={formattedAdStatus}
      label={t('Ad status')}
      selected={selectedAdStatus}
      value={displayedValue}
      onSelect={onSelect}
      searchable={false}
      searchPlaceholder="search"
      clearSelection={clearSelection}
    />
  );
};
