import type { Locale } from '@seek/melways-sites';
import { absoluteDateFormatter } from '@seek/unified-display-date-time';
import { format, type Locale as DateLocale, isBefore, isEqual } from 'date-fns';
import { enAU, enNZ, id, th, zhHK } from 'date-fns/locale';

import { getLocale } from 'src/config';

const localeMap: Record<Locale, DateLocale> = {
  'en-AU': enAU,
  'en-HK': enAU,
  'en-ID': enAU,
  'en-MY': enAU,
  'en-NZ': enNZ,
  'en-PH': enAU,
  'en-SG': enAU,
  'en-TH': enAU,
  'id-ID': id,
  'th-TH': th,
  'zh-HK': zhHK,
};

interface FormatDateOptions {
  date: Date | null;
  dateFormat?: string;
  locale?: Locale;
}

interface FormatDateWithUnifiedDisplayOptions {
  date: Date;
  locale?: Locale;
}

const formatDate = ({
  date,
  dateFormat = 'dd MMM yyyy',
  locale = getLocale(),
}: FormatDateOptions): string => {
  if (!date) {
    return '';
  }
  return format(date, dateFormat, { locale: localeMap[locale] });
};

const formatDateWithUnifiedDisplay = ({
  date,
  locale = getLocale(),
}: FormatDateWithUnifiedDisplayOptions): string => {
  const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

  const unifiedFormattedDate = absoluteDateFormatter({
    date: new Date(date),
    format: 'short',
    timezone,
    locale,
  });
  return unifiedFormattedDate.isValid
    ? unifiedFormattedDate.formattedDate
    : formatDate({ date });
};

const isDateBefore = (date: Date, comparisonDate: Date): boolean =>
  isBefore(date, comparisonDate);

const isDateEqual = (date: Date, comparisonDate: Date): boolean =>
  isEqual(date, comparisonDate);

export { formatDate, formatDateWithUnifiedDisplay, isDateBefore, isDateEqual };
