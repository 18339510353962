import { useGetFeatureToggleVariant } from '@seek/hirer-feature-toggle';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Columns,
  Column,
  Stack,
  Text,
  Badge,
  Inline,
} from 'braid-design-system';
import { differenceInDays, startOfDay } from 'date-fns';
import { useCallback } from 'react';

import { useConfig } from 'src/App/ConfigContext';
import { getLocale } from 'src/config';
import type { Money } from 'src/gql/graphql';
import type { AdStatus } from 'src/types/AdPerformanceTypes';
import type { AdRatingType } from 'src/types/AdUsageTypes';
import { formatCurrency } from 'src/utils/currency';
import { formatDateWithUnifiedDisplay } from 'src/utils/date';
import { FEATURE_ADD_ONS } from 'src/utils/featureToggle/featureKeys';

import { ExternalLinkWithRef } from '../ExternalLink/ExternalLink';
import { WrapBadge } from '../WrapBadge/WrapBadge';
import { ZStack } from '../ZStack/ZStack';
import { ZStackItem } from '../ZStackItem/ZStackItem';

import translations from './.vocab';
import { AdRatingLabel } from './AdRatingLabel';

import * as styles from './jobAdListItem.css';

export interface JobListItemProps {
  adRating: AdRatingType;
  applications: number;
  jobCreatedDate: string;
  locationLabel: string;
  hidePrice: boolean;
  isLatestGroupApac: boolean;
  isRefresh: boolean;
  isRepost: boolean;
  jobId: string;
  groupKeyApac: string;
  jobTitle: string;
  status: AdStatus;
  totalNetAmount: Money;
  otherBudgetAmount: Money;
  productDisplayName: string;
  addOns: string[];
  userFullName: string | null;
  hoveredGroupKey?: string;
  onItemClicked?: (jobId: string) => void;
  onJobAdHover: (jobId: string | undefined) => void;
}

export const JobAdListItem = ({
  adRating,
  applications,
  jobCreatedDate,
  locationLabel,
  hidePrice,
  isLatestGroupApac,
  isRefresh,
  isRepost,
  jobId,
  jobTitle,
  status,
  totalNetAmount,
  otherBudgetAmount,
  hoveredGroupKey,
  groupKeyApac,
  productDisplayName,
  addOns,
  userFullName = null,
  onJobAdHover,
  onItemClicked,
}: JobListItemProps) => {
  const { t } = useTranslations(translations);
  const { urlResolver, locale } = useConfig();
  const isAddOnsEnabled = useGetFeatureToggleVariant(FEATURE_ADD_ONS);
  const jobAdReportUrl = urlResolver(`/analytics/ad-performance/${jobId}`);
  // const [ratingHovered, setRatingHovered] = useState(false);
  const daysLive = differenceInDays(
    startOfDay(new Date()),
    startOfDay(new Date(jobCreatedDate)),
  );
  const EXPIRING_DAYS = 30; // based on business rule
  const daysRemaining = Math.max(0, EXPIRING_DAYS - daysLive);
  const prettyJobCreatedDate = formatDateWithUnifiedDisplay({
    date: new Date(jobCreatedDate),
    locale,
  });
  const numberFormatter = new Intl.NumberFormat(getLocale());

  const onItemClickedEvent = () => {
    if (onItemClicked) {
      onItemClicked(jobId);
    }
  };

  const onMouseEnterRating = useCallback(() => {
    onJobAdHover(groupKeyApac);
  }, [groupKeyApac, onJobAdHover]);

  const onMouseLeaveRating = useCallback(() => {
    onJobAdHover(undefined);
  }, [onJobAdHover]);

  const hasAdPerformanceLink =
    isLatestGroupApac &&
    adRating !== 'UNAVAILABLE' &&
    adRating !== 'NOT_INCLUDED';

  const JobAdDescription = () => (
    <Box paddingLeft="medium">
      <Stack space="xsmall">
        <Stack space="xsmall">
          <Text size="small" maxLines={1}>
            {jobTitle}
          </Text>
        </Stack>
        <Stack space="xsmall">
          <Text size="small" maxLines={1}>
            {locationLabel}
          </Text>
          {status === 'ACTIVE' && (
            <Text size="small" tone="secondary" maxLines={2}>
              {t('Live job posted date with remaining days', {
                prettyJobCreatedDate,
                daysRemaining,
                daysRemainingText: numberFormatter.format(daysRemaining),
              })}
            </Text>
          )}
          {status === 'EXPIRED' && (
            <Text size="small" tone="secondary" maxLines={1}>
              {t('Expired job posted date', {
                prettyJobCreatedDate,
              })}
            </Text>
          )}
          {isRepost || isRefresh ? (
            <Inline space="xxsmall">
              {isRepost && <Badge tone="promote">{t('Repost')}</Badge>}
              {isRefresh && <Badge tone="positive">{t('Refresh')}</Badge>}
            </Inline>
          ) : null}
        </Stack>
      </Stack>
    </Box>
  );

  const JobAdApplication = () => (
    <Box style={{ height: '52px' }}>
      <Text size="small" align="left">
        {numberFormatter.format(applications)}
      </Text>
    </Box>
  );

  const JobAdPerformance = () => (
    <AdRatingLabel
      adRating={adRating}
      hoveredGroupKey={hoveredGroupKey}
      jobId={jobId}
      onMouseEnterRating={onMouseEnterRating}
      onMouseLeaveRating={onMouseLeaveRating}
    />
  );

  const JobAdPostedBy = () => (
    <Text size="small" maxLines={1}>
      {userFullName === null ? t('Unspecified user') : userFullName}
    </Text>
  );

  const JobAdCostType = () => (
    <Stack space="xsmall">
      {!hidePrice && (
        <Stack space="xsmall">
          <Text size="small">{formatCurrency(totalNetAmount)}</Text>
          {otherBudgetAmount.value > 0 ? (
            <WrapBadge tone="neutral">
              {t('Amount from other budget', {
                amount: formatCurrency(otherBudgetAmount),
              })}
            </WrapBadge>
          ) : null}
        </Stack>
      )}
      <Text size="small" maxLines={1}>
        {t('Ad name', {
          adName: productDisplayName,
        })}
      </Text>
      {isAddOnsEnabled?.value && addOns.length > 0 ? (
        <Text size="small" maxLines={1}>
          {t('Add-ons details', {
            amountText: addOns.length.toString(),
            amount: addOns?.length,
          })}
        </Text>
      ) : null}
    </Stack>
  );

  const AdPerformanceLink = () => (
    <ExternalLinkWithRef
      className={styles.link}
      data-id="ui-job-menu-view-report-button"
      href={jobAdReportUrl}
      target="_blank"
      title={t('Click to view detailed report for this Job ad')}
      onClick={onItemClickedEvent}
    />
  );

  return (
    <ZStack height="full" width="full">
      <ZStackItem isRelativeLayer>
        <Box
          cursor={hasAdPerformanceLink ? 'pointer' : 'default'}
          paddingY="medium"
        >
          <Columns space="large" alignY="top" align="left">
            <Column width="1/3">
              <JobAdDescription />
            </Column>
            <Column width="2/3">
              <Columns space="large" alignY="top" align="left">
                <Column width="1/5">
                  <JobAdApplication />
                </Column>
                <Column width="1/4">
                  <JobAdPerformance />
                </Column>
                <Column width="1/5">
                  <JobAdPostedBy />
                </Column>
                <Column>
                  <JobAdCostType />
                </Column>
              </Columns>
            </Column>
          </Columns>
        </Box>
      </ZStackItem>
      {hasAdPerformanceLink && (
        <ZStackItem>
          <AdPerformanceLink />
        </ZStackItem>
      )}
    </ZStack>
  );
};
