import {
  MultiSelectDropdown,
  type MultiSelectDropdownOnSelectState,
} from '@seek/multi-select-dropdown';
import { useTranslations } from '@vocab/react';
import { useContext } from 'react';

import { IsFilterContext } from 'src/context/IsFilterContext';
import {
  actionTypes,
  useAdUsageFiltersContext,
} from 'src/context/adUsageFilters';
import type { JobTitleOption } from 'src/types/AdUsageFiltersResponse';
import {
  trackEvent,
  mapDropdownFilterMethod,
} from 'src/utils/tealiumAUREventTracker';

import { getDisplayValue } from '../Filters.formatters';

import translations from './.vocab';
import { buildJobTitleData } from './JobTitle.formatters';

export interface JobTitleProps {
  jobTitles: JobTitleOption[];
  enableLazyLoading?: boolean;
}

export const JobTitle = ({
  jobTitles,
  enableLazyLoading = false,
}: JobTitleProps) => {
  const {
    adUsageFilters: { jobTitles: selectedJobTitleIds },
    updateAdUsageFilters,
  } = useAdUsageFiltersContext();
  const { t } = useTranslations(translations);

  const jobTitleData = buildJobTitleData(jobTitles);

  const displayedValue = getDisplayValue(
    selectedJobTitleIds,
    {},
    jobTitleData,
    t,
    t('All job titles'),
  );

  const {
    setIsFilterJobTitle,
    isFilterAdPerformance,
    isFilterAdType,
    isFilterClassification,
    isFilterLocation,
    isFilterAccountHierachy,
    isFilterAddOns,
    isFilterAdId,
    isFilterAdStatus,
    isFilterRepost,
    isFilterTipsToImproveAds,
    isFilterUser,
    isFilterBudget,
  } = useContext(IsFilterContext);

  const onSelect = (
    value: string,
    dropdownState: MultiSelectDropdownOnSelectState,
  ) => {
    const newSelected = selectedJobTitleIds.includes(value)
      ? [
          ...selectedJobTitleIds.filter(
            (singleSelected) => singleSelected !== value,
          ),
        ]
      : [...selectedJobTitleIds, value];

    trackEvent('filter_pressed', {
      isFilterJobTitle: newSelected.length > 0,
      isFilterAdPerformance,
      isFilterAdType,
      isFilterClassification,
      isFilterLocation,
      isFilterAccountHierachy,
      isFilterAddOns,
      isFilterAdId,
      isFilterAdStatus,
      isFilterRepost,
      isFilterTipsToImproveAds,
      isFilterUser,
      isFilterBudget,
      filterType: 'job title',
      filterMethod: mapDropdownFilterMethod(dropdownState),
    });

    if (selectedJobTitleIds.includes(value)) {
      updateAdUsageFilters({
        type: actionTypes.UPDATE_JOB_TITLES,
        value: selectedJobTitleIds.filter((ids: string) => ids !== value),
      });
    } else {
      updateAdUsageFilters({
        type: actionTypes.UPDATE_JOB_TITLES,
        value: [...selectedJobTitleIds, value],
      });
    }
    setIsFilterJobTitle(newSelected.length > 0);
  };

  const clearSelection = () =>
    updateAdUsageFilters({
      type: actionTypes.UPDATE_JOB_TITLES,
      value: [],
    });

  return (
    <MultiSelectDropdown
      options={jobTitleData}
      label={t('Job title')}
      selected={selectedJobTitleIds}
      value={displayedValue}
      onSelect={onSelect}
      lazyLoadingEnabled={enableLazyLoading}
      searchPlaceholder={`${t('Search job titles')}...`}
      clearSelection={clearSelection}
    />
  );
};
